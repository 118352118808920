import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { FormInput, FormSelect } from "../../components/forms";
import { Button } from "../../components";

const TourPaymentForm = ({ onSubmit, onCancel, onBack, step, maxStep, defaultConditions }) => {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const progressValue = step > 0 ? (step * 100) / maxStep / 100 : 0;

    return (
        <>
            <div
                className='h-4 w-full bg-v-accent transition-transform origin-left rounded mb-2'
                style={{ transform: `scaleX(${progressValue})` }}
            />
            <form onSubmit={handleSubmit(onSubmit)} className='v__grid'>
                <GenerateDivisionsForms step={step} t={t} control={control} conditions={defaultConditions} errors={errors} />
                <div className='v__form__footer v__grid__item v__grid__item--col-12'>
                    <Button bg status='darker' onClick={onCancel}>
                        {t("cancel")}
                    </Button>
                    {step > 1 && (
                        <Button bg status='darker' onClick={onBack}>
                            {t("back")}
                        </Button>
                    )}

                    <Button bg status='accent' type='submit'>
                        {step === maxStep ? t("save") : t("continue")}
                        {/* {payload ? lang?.save_changes : lang?.add_group} */}
                    </Button>
                </div>
            </form>
        </>
    );
};


const GenerateDivisionsForms = (props) => {
    if (props.conditions === undefined) {
        return null;
    }

    const startIndex = props.step === 1 ? 0 : 11 + (props.step - 2) * 10;
    const endIndex = props.step === 1 ? 11 : 11 + (props.step - 1) * 10;

    var slicedConditions = props.conditions.data.slice(startIndex, endIndex);

    return slicedConditions.map((condition, index) => {
        let field_name = condition.title;
        let slug_name = condition.field;
        let payment_type = condition.paymentOption?.type;
        let defaultValue = null;

        if (slug_name === "full-price") {
            defaultValue = { value: 0 };
        }
        if (payment_type !== undefined) {
            defaultValue = { value: payment_type, label: props.t(payment_type) };
        }

        return (
            <>
                {(slug_name === "full-price") && (
                    <Controller
                        render={({ field }) => (
                            <FormInput
                                groupClassName={cn("v__grid__item v__grid__item--col-12 v__grid__item--col-sm-12", {
                                    "v__form__group--incorrect": props.errors?.type,
                                })}
                                label={props.t(field_name)}
                                error={props.errors?.type && props.t(`${props.errors?.type?.type}`)}
                                {...field}
                            />
                        )}
                        control={props.control}
                        key={"tours_" + condition.id}
                        name={"tours[" + condition.id + "]"}
                        defaultValue={defaultValue.value}
                        rules={{ required: false }}
                    />
                )}

                {(slug_name !== "full-price") && (
                    <Controller
                        render={({ field }) => (
                            <FormSelect
                                groupClassName={cn("v__grid__item v__grid__item--col-6 v__grid__item--col-sm-12", {
                                    "v__form__group--incorrect": props.errors?.type,
                                })}
                                label={props.t(field_name)}
                                options={[
                                    { value: "full_paid", label: props.t("full_paid") },
                                    { value: "half_paid", label: props.t("half_paid") },
                                    { value: "no_paid", label: props.t("no_paid") },
                                ]}
                                error={props.errors?.type && props.t(`${props.errors?.type?.type}`)}
                                {...field}
                            />
                        )}
                        control={props.control}
                        key={"tours_" + condition.id}
                        name={"tours[" + condition.id + "]"}
                        defaultValue={defaultValue}
                        rules={{ required: false }}
                    />
                )}
            </>
        );
    });
};

export default TourPaymentForm;
