import { useTranslation } from "react-i18next";

import { Card, Modal } from "components";
import { useEffect, useState } from "react";
import { useUpdateToursPaymentOptions, useTourDefaultConditions } from "hooks/query/tours";
import { TourPaymentForm } from ".";

const TourPaymentModal = ({ category_id, onClose }) => {
    const { t } = useTranslation();

    const [step, setStep] = useState(1);
    const [maxStep, setMaxStep] = useState(7);

    const { data: defaultConditions } = useTourDefaultConditions({ custom: { category_id } });

    // useEffect(() => {
    //     setMaxStep(defaultConditions?.data.length / 10);
    // }, [defaultConditions]);

    const { mutate: update, isLoading: isLoadingUpdate } = useUpdateToursPaymentOptions({
        onSuccess: (res) => {
            onClose();
        },
    });

    const handleOnSubmit = (data) => {
        if (step === maxStep) {
            data = { category_id: category_id, ...data };
            update({ data });
        } else {
            setStep((prev) => prev + 1);
        }
    };

    const handleOnBack = () => setStep((prev) => prev - 1);

    const shouldRender = defaultConditions;
    const isLoading = isLoadingUpdate;

    return (
        <Modal onClose={onClose}>
            <Card
                className='v__modal v__card--overflow-able'
                header={t("setPaymentTypeForTours") + " - Step " + step + "/" + maxStep}
                relativeLoading={isLoading}
                dismissible={onClose}
            >
                {shouldRender && (
                    <TourPaymentForm
                        defaultConditions={defaultConditions}
                        step={step}
                        maxStep={maxStep}
                        onSubmit={handleOnSubmit}
                        onBack={handleOnBack}
                        setStep={setStep}
                        onCancel={onClose}
                    />
                )}
            </Card>
        </Modal>
    );
};

export default TourPaymentModal;
