import { useTranslation } from "react-i18next";
import { useAuth } from "context";

import { Card, Modal } from "components";
import { useEffect, useState } from "react";
import { useUpdateTour, useTourDefaultConditions, useTour } from "hooks/query/tours";
import TourForm from "./TourForm";

const TourModal = ({ categoryId, payload, onClose }) => {
    const { t } = useTranslation();
    const { userCan } = useAuth();

    const [step, setStep] = useState(1);
    const maxStep = 8;
    const [fullPrice, setFullPrice] = useState(0);

    const { data: defaultConditions } = useTourDefaultConditions({
        custom: {
            category_id: categoryId,
        },
        enabled: userCan("tours_advanced_edit"),
    });

    const { data: tour } = useTour(payload?.id, {
        custom: {
            route_category_id: categoryId,
        },
        enabled: Boolean(payload?.id)
    });


    const { mutate: update, isLoading: isLoadingUpdate } = useUpdateTour({
        onSuccess: (res) => {
            onClose();
        },
    });

    const tourData = tour?.data;
    useEffect(() => {
        setFullPrice(() => tourData?.preinvoiceSettings?.full_price);
    }, [tourData, setFullPrice])


    const defaultValues = {
        title: tourData?.title,
        full_price: tourData?.preinvoiceSettings?.full_price,
        price_per_parcel: tourData?.preinvoiceSettings?.price_per_parcel,
        van_per_day: tourData?.preinvoiceSettings?.van_per_day,
        fuel_per_day: tourData?.preinvoiceSettings?.fuel_per_day,
    };

    if (Boolean(tourData)) {
        const divisionToBeAdded = {};
        tourData.conditions.forEach((condition) => {
            divisionToBeAdded[condition.title] = condition.rate;
        });
        defaultValues.conditions = divisionToBeAdded;
    }

    const handleOnSubmit = (data) => {
        if (step === maxStep) {
            if (Boolean(payload)) {
                data = { route_category_id: categoryId, ...data }
                update({ id: payload.id, data });
            } else {
                //nope
            }
        } else {
            setStep((prev) => prev + 1);
        }
    };

    const handleOnBack = () => setStep((prev) => prev - 1);

    const shouldRender = (payload && tourData) || !payload;
    const isLoading = isLoadingUpdate;

    return (
        <Modal onClose={onClose}>
            <Card
                className='v__modal v__card--overflow-able'
                header={t("addConditionsAndPreinvoiceSettings") + " - " + tourData?.title + " - Step " + step + "/" + maxStep}
                relativeLoading={isLoading}
                dismissible={onClose}
            >
                {shouldRender && (
                    <TourForm
                        defaultConditions={defaultConditions}
                        step={step}
                        fullPrice={fullPrice}
                        maxStep={maxStep}
                        onSubmit={handleOnSubmit}
                        onBack={handleOnBack}
                        defaultValues={defaultValues}
                        setStep={setStep}
                        onCancel={onClose}
                    />
                )}
            </Card>
        </Modal>
    );
};

export default TourModal;
